<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col">
      <div class="pub_-flex-row pub_-self-center group space-x-40">
        <left-nav></left-nav>

        <div class="pub_-flex-col pub_-flex-auto section_3">
          <span class="pub_-self-start font_4 text_11 text_12"
          >我的账户</span
          >
          <span class="pub_-self-start font_5 text_11 text_14">可用余额</span>
          <div
            class="pub_-flex-row pub_-justify-between pub_-items-end group_2"
          >
            <div class="pub_-flex-row pub_-items-baseline space-x-3">
              <span class="text_11 text_18">￥</span>
              <span class="text_11 text_17">{{ surplusNum }}</span>
            </div>
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3 btn_sty"
              @click="openSha"
            >
              <span class="font_2 text_16">去充值</span>
            </div>
          </div>
          <div class="divider"></div>
          <span class="pub_-self-start font_4 text_11 text_22">充值记录</span>

          <div class="wall_con">
            <div
              class="pub_-flex-row pub_-justify-between pub_-items-center group_3"
              v-for="(item, i) in showList"
              :key="i"
            >
              <div class="pub_-flex-col group_4 space-y-7">
                <span class="font_5 text_11 text_23">{{ item.name }}</span>
                <div class="pub_-flex-row space-x-4">
                  <span class="font_8 text_11">{{ item.createTime }}</span>
                </div>
              </div>
              <div class="group_5">
                <!-- <span class="font_7 text_11 text_25">-</span> -->
                <span class="font_6 text_11">{{ item.orderMoney }}</span>
              </div>
            </div>
          </div>

          <Pagination
            @change="changePage"
            :current="pagination.p"
            :pageSize="pagination.pageSize"
            :total="total"
            :hideOnSinglePage="hideOnSinglePage"
            :showQuickJumper="true"
            :showTotal="true"
            placement="right"
            v-if="pagination && total"
          />
        </div>
      </div>
    </div>
    <foot-box></foot-box>

    <!-- 充值 -->
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
      v-if="topUP == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row section_6a space-x-463a">
          <span class="font_4a text_14a">余额充值</span>
          <img
            class="pub_-shrink-0 imagea"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="closeSha()"
          />
        </div>
        <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a">充值金额</span>
          <div class="pub_-flex-row group_3a space-x-477a">
            <span class="text_11a text_21a">{{ numP }}</span>
            <!-- <img class="pub_-shrink-0 image_2a" src="../../assets/9c674996042f411fd048987216946219.png" /> -->
          </div>
          <div class="divider_2a"></div>

          <div class="pub_-flex-row group_4a space-x-16a">
            <div
              class="pub_-flex-row pub_-items-center section_8a space-x-7a"
              v-for="(item, i) in ruleList"
              :key="i"
              @click="selRul(item.id, item.payMoney)"
              :class="pas.ruleId == item.id ? 'on' : ''"
            >
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 section_11a"
              >
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center section_13a"
                >
                  <div class="pub_-flex-col pub_-justify-start text-wrapper_5a">
                    <span class="font_8a text_11a text_28a">￥</span>
                  </div>
                </div>
              </div>
              <span class="font_7a text_11a text_24a">{{ item.name }}</span>
            </div>
          </div>
          <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a">充值方式</span>

            <el-radio
              v-for="(item, i) in payChanne"
              :key="i"
              v-model="pas.payChannelId"
              :label="item"
            >
              <template v-if="item == 2">
                <img
                  class="image_3a image_4a"
                  src="../../assets/aa8eeff952b2db86c0b4584a0132d942.png"
                />
                <span class="font_10a text_11a text_35a">支付宝</span>
              </template>
<!--              <template v-else-if="item == 1">-->
<!--                <img-->
<!--                  class="image_3a image_5a"-->
<!--                  src="../../assets/a129daff2b6c1c1917ddd5efe52c95f1.png"-->
<!--                />-->
<!--                <span class="font_10a text_11a text_35a">微信</span>-->
<!--              </template>-->
              <template v-else>
                <img
                  class="image_3a image_5a"
                  src="../../assets/be355aa026f71df00d26fb262cf8278e.png"
                />
                <span class="font_10a text_11a text_35a">余额</span>
              </template>
            </el-radio>
          </div>
          <!-- <img
            class="pub_-self-center image_7a"
            src="../../assets/9f632cb4ede9b6d32741cf5348eaf96d.png"
          /> -->

          <div class="btn_sty" @click="confirmRecharge">确认充值</div>
        </div>
      </div>
    </div>


    <!-- 确认充值 -->
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
      v-if="ackShow == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row section_6a space-x-463a">
          <span class="font_4a text_14a">确认充值</span>
          <img
            class="pub_-shrink-0 imagea"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="beforeDestroy()"
          />
        </div>
        <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a"
          >充值金额:<span>{{ numP }}</span></span
          >

          <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a"
            >充值方式:
<!--              <span v-if="pas.payChannelId == 1">微信</span>-->
              <span v-if="pas.payChannelId == 2">支付宝</span>
              <span v-else>余额</span>
            </span>
          </div>


          <img v-if="pas.payChannelId == 1" class="pub_-self-center image_7a" :src="codeImg"/>
          <div v-else-if="pas.payChannelId == 2" class="hint_pay">支付中...</div>

          <!-- <div class="btn_sty">确认充值</div> -->
        </div>
      </div>


    </div>


  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";
import QRCode from "qrcode";

export default {
  components: {NavBox, FootBox, LeftNav, Pagination},
  data() {
    return {
      topUP: false,

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      showList: [], //显示的列表
      conC: [], //完整的列表

      ruleList: [],
      numP: 0,
      pas: {
        ruleId: "",
        payChannelId: "1",
      },
      payChanne: [],
      radio: "1",

      ackShow: false,
      codeImg: "",

      apiUrl: "http://39.100.254.122/api.order/getPayStatus",
      interval: 1000, // 每隔5秒轮询一次
      timer: null,
      data: null,
      surplusNum: 0,
    };
  },
  created() {
    // 定义轮询函数
    // this.pollData = () => {
    //   fetch(this.apiUrl)
    //     .then((response) => {
    //       if (response.ok) {
    //         // 解析响应数据
    //         return response.json();
    //       } else {
    //         throw new Error("API request failed!");
    //       }
    //     })
    //     .then((data) => {
    //       // 获取到数据后进行相应的处理
    //       console.log("Got data:", data);
    //       // 更新组件渲染数据
    //       this.data = data;
    //       if(data.payStatus == 2){
    //         window.location.href = data.jumpUrl;
    //         this.beforeDestroy();
    //       }
    //
    //
    //     })
    //     .catch((error) => {
    //       // 处理请求失败的情况
    //       console.error(error);
    //     });
    // };
    //
    //
    this.ajaxH();
  },
  methods: {
    async ajaxH() {
      let param = {};
      const {data: res} = await this.$http.post(
        "/api.recharge/getPageList",
        param
      );
      console.log(res)

      this.showList = res.data.data.slice(0, res.data.per_page);
      this.conC = res.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;

      let params = {};
      const {data: rul} = await this.$http.post(
        "/api.recharge/getRuleList",
        params
      );
      // console.log(rul);
      this.ruleList = rul.data.ruleList;
      this.numP = this.ruleList[0].payMoney;
      this.pas.ruleId = this.ruleList[0].id;
      this.payChanne = rul.data.payChannelId.split(",");
      this.pas.payChannelId = this.payChanne[0];


      this.BalanceInquiry();

    },

    async BalanceInquiry() {
      let self = this;
      let param = {};
      //余额
      const {data: ress} = await this.$http.post(
        "/api.student/getInfo",
        param
      );
      console.log(ress)
      self.surplusNum = ress.data.balance;
    },
    selRul(id, name) {
      this.numP = name;
      this.pas.ruleId = id;
    },
    closeSha() {
      this.beforeDestroy()
      this.topUP = false;

    },
    openSha() {
      this.topUP = true;
    },
    async confirmRecharge() {
      let that = this;
      this.topUP = false;
      let param = this.pas;
      const {data: res} = await this.$http.post(
        "/api.recharge/placeOrder",
        param
      );
      console.log(res.data);

      if (that.pas.payChannelId == '2') {
        window.open(res.data.data.url, '_blank');
      } else {
        const QRCode = require("qrcode");
        const text = res.data.qrCode;
        QRCode.toDataURL(text, function (err, url) {
          that.codeImg = url;
        });


      }
      this.ackShow = true;
      this.timer = setInterval(() => {
        this.pollData(res.data.orderId);
      }, this.interval);

    },
    async pollData(id) {
      const {data: res} = await this.$http.post("/api.order/getPayStatus", {id: id})
      // console.log(res)
      if (res.data.payStatus == 2) {
        this.beforeDestroy();
        window.location.href = res.data.jumpUrl;  //支付成功跳转页面

      }
    },
    beforeDestroy() {
      this.ackShow = false;
      // 清除轮询定时器
      clearInterval(this.timer);
    },

    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.conC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  line-height: 0.17rem;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group {
  padding-top: 0.4rem;
  width: 11.8rem;
  margin-bottom: 0.5rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}

.text_10 {
  margin-left: 0.19rem;
}

.text_13 {
  margin-left: 0.17rem;
}

.text_15 {
  margin-left: 0.16rem;
}

.text_19 {
  margin-left: 0.17rem;
}

.text_20 {
  margin-left: 0.16rem;
}

.text-wrapper_4 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_21 {
  margin-left: 0.17rem;
  line-height: 0.13rem;
}

.text_24 {
  margin-left: 0.17rem;
}

.section_4 {
  background-color: #ffffff;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.section_5 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.text_26 {
  margin-right: 1.27rem;
  color: #2080f7;
  line-height: 0.13rem;
}

.section_3 {
  padding: 0.38rem 0.31rem calc(1rem + 32px) 0.39rem;
  background-color: #ffffff;
  width: 9.42rem;
  min-height: 8.74rem;


  position: relative;

  .m-pagination {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    width: 100%;
    padding-right: 0.5rem;
  }
}

.font_4 {
  font-size: 0.18rem;
  line-height: 0.17rem;
  font-weight: 700;
  color: #2b2c30;
}

.font_5 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.group_2 {
  margin-top: 0.03rem;
}

.space-x-3 > *:not(:first-child) {
  margin-left: 0.03rem;
}

.text-wrapper_3 {
  margin-right: 0.09rem;
  border-radius: 0.04rem;
  width: 1.49rem;
  height: 0.45rem;
}

.text_16 {
  letter-spacing: 0.025rem;
}

.divider {
  margin-right: 0.09rem;
  margin-top: 0.39rem;
  background-color: #f3f6fa;
  height: 0.01rem;
}

.group_3 {
  margin-top: 0.18rem;
}

.group_4 {
  width: 1.67rem;
}

.space-x-4 > *:not(:first-child) {
  margin-left: 0.04rem;
}

.font_8 {
  font-size: 0.12rem;
  font-family: OPPOSans;
  line-height: 0.1rem;
  color: #a1a9b2;
}

.group_5 {
  line-height: 0.19rem;
  display: flex;
  align-items: center;
}

.font_7 {
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.1rem;
  color: #2b2c30;
}

.font_6 {
  font-size: 0.24rem;
  font-family: OPPOSans;
  line-height: 0.19rem;
  color: #2b2c30;
}

.text_11 {
  text-transform: uppercase;
}

.text_25 {
  line-height: 0.02rem;
}

.text_23 {
  line-height: 0.16rem;
}

.text_22 {
  margin-top: 0.4rem;
  line-height: 0.18rem;
}

.text_17 {
  color: #e4393c;
  font-size: 0.32rem;
  font-family: OPPOSans;
  line-height: 0.28rem;
}

.text_18 {
  color: #e4393c;
  font-size: 0.2rem;
  font-family: OPPOSans;
  line-height: 0.16rem;
}

.text_14 {
  margin-top: 0.39rem;
  font-weight: 300;
  line-height: 0.16rem;
}

.text_12 {
  line-height: 0.18rem;
}

.group_6 {
  margin-top: 0.2rem;
  padding: 0.2rem 0;
  border-top: solid 0.01rem #f3f6fa;
  border-bottom: solid 0.01rem #f3f6fa;
}

.group_7 {
  width: 1.09rem;
}

.text_27 {
  line-height: 0.16rem;
}

.group_8 {
  padding: 0.2rem 0;
  border-bottom: solid 0.01rem #f3f6fa;
}

.group_9 {
  width: 1.2rem;
}

.space-y-7 > *:not(:first-child) {
  margin-top: 0.07rem;
}

.text_28 {
  line-height: 0.16rem;
}

.wall_sha {
  &.section_3a {
    padding: 2.4rem 0 2.86rem;
    background-color: #051b3733;
  }

  .font_4a {
    font-size: 0.18rem;
    line-height: 0.17rem;
    font-weight: 500;
    color: #2b2c30;
  }

  .font_10a {
    font-size: 0.16rem;
    line-height: 0.1rem;
    color: #2b2c30;
  }

  &.pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
  }

  .group_1a {
    width: 6rem;
  }

  .section_6a {
    padding: 0.26rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0px 0px;
    border-bottom: solid 0.01rem #f3f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_14a {
    line-height: 0.18rem;
    width: 1.2rem;
    display: block;
  }

  .imagea {
    margin-right: 0.06rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .section_7a {
    padding: 0.34rem 0.2rem 0.39rem;
    background-color: #ffffff;
    border-radius: 0px 0px 0.14rem 0.14rem;

    .btn_sty {
      width: 1.5rem;
      margin: 0.8rem auto 0;
      height: 0.45rem;
      font-size: 0.18rem;
    }
  }

  .font_6a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_18a {
    line-height: 0.16rem;
  }

  .group_3a {
    margin-top: 0.13rem;
  }

  .space-x-477a > *:not(:first-child) {
    margin-left: 4.77rem;
  }

  .image_2a {
    width: 0.24rem;
    height: 0.24rem;
  }

  .divider_2a {
    margin-top: 0.06rem;
    background-color: #2080f7;
    height: 0.01rem;
  }

  .group_4a {
    margin-top: 0.2rem;
    flex-wrap: wrap;

  }

  .space-x-16a > *:not(:first-child) {
    margin-left: 0.16rem;
  }

  .section_8a {
    padding: 0.1rem 0;
    background-color: #f2f8ff;
    height: 0.44rem;
    width: calc((100% - .4rem) / 3);
    margin-right: .2rem;
    margin-left: 0 !important;
    margin-top: .2rem;
    justify-content: center;
    cursor: pointer;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &.on {
      cursor: auto;
      background-color: rgba(32, 128, 247, 1);

      .font_7a {
        color: #fff;
      }
    }
  }

  .space-x-7a > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .section_11a {
    background-image: url("../../assets/b39e5272ae1fa5384043d72be101830d.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .section_13a {
    padding: 0.02rem 0;
    background-image: url("../../assets/9807e0680566a77a665fabdbe54aace1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.2rem;
  }

  .text-wrapper_5a {
    padding: 0.02rem 0;
    background-image: url("../../assets/c7222962c8899b1a18e7d0ae663528a4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .font_8a {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.1rem;
    color: #ed8a18;
  }

  .font_7a {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.17rem;
    color: #2080f7;
  }

  .text_11a {
    text-transform: uppercase;
  }

  .text_28a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_21a {
    color: #2b2c30;
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.23rem;
  }

  .text_38a {
    line-height: 0.16rem;
  }

  .text_37a {
    line-height: 0.16rem;
  }

  .text_32a {
    line-height: 0.02rem;
  }

  .text_30a {
    line-height: 0.16rem;
  }

  .text_27a {
    margin-top: 0.4rem;
    line-height: 0.18rem;
  }

  .text_19a {
    color: #e4393c;
    font-size: 0.2rem;
    font-family: OPPOSans;
    line-height: 0.16rem;
  }

  .text_15a {
    font-weight: 300;
    line-height: 0.16rem;
  }

  .text_12a {
    line-height: 0.18rem;
  }

  .text_24a {
    line-height: 0.18rem;
  }

  .section_9a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #2080f7;
    height: 0.44rem;
  }

  .section_12a {
    background-image: url("../../assets/c3c557f93ff35c3de06a9b0c39f24b4c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .text_29a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_25a {
    color: #ffffff;
    line-height: 0.18rem;
  }

  .section_10a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #f2f8ff;
    height: 0.44rem;
  }

  .space-x-8a > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text_26a {
    line-height: 0.18rem;
  }

  .group_8a {
    margin-top: 0.4rem;

    .el-radio {
      display: flex;
      align-content: center;
      margin-left: 0.3rem;
      margin-right: 0;

      ::v-deep .el-radio__input {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-radio__label {
        display: flex;
        align-items: center;
      }
    }
  }

  .hint_pay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
    font-family: SourceHanSansCN;
    font-size: 0.2rem;
    color: #2b2c30;


  }

  .text_33a {
    line-height: 0.16rem;
  }

  .image_3a {
    border-radius: 0.04rem;
    width: 0.24rem;
    height: 0.24rem;
  }

  .image_6a {
    width: 0.16rem;
    height: 0.16rem;
  }

  .text_35a {
    margin-left: 0.08rem;
    line-height: 0.15rem;
  }

  .image_7a {
    margin-top: 0.4rem;
    width: 1.81rem;
    height: 1.8rem;
  }
}
@media screen and (orientation: portrait){
  .group{
    width: 100%;
    flex-wrap: wrap;
    padding-left: .45rem;
    padding-right: .45rem;
    .section_3{
      margin-left: 0;
      margin-top: .4rem;
      padding: .3rem .3rem calc(32px + 1rem);
    }

    .font_4{
      font-size: .28rem;
      line-height: 1;
    }
    .font_5{
      font-size: .22rem;
      line-height: 1;
    }
    .text_17{
      font-size: .42rem;
      line-height: 1;
    }
    .text-wrapper_3{
      margin-right: 0;
      height: .6rem;

      .font_2{
        font-size: .24rem;
      }
    }
.font_8{
  font-size: .2rem;
  line-height: 1;
  margin-top: .15rem;
}
    .space-x-4{
      margin-top: 0;
    }
    .group_3{
      margin-top: .4rem;
      margin-bottom: .4rem;


    }
    .group_4{
width: 65%;
    }



  }


  .wall_sha {
    &.section_3a {


    }

    .font_4a {
      font-size: 0.28rem;
      line-height:1;


    }

    .font_10a {
      font-size: .2rem;
      line-height:1;
    }

    &.pos {


    }

    .group_1a {
width: 90%;

    }

    .section_6a {


    }

    .text_14a {


    }

    .imagea {
      margin-right: .1rem;
      width: 0.4rem;
      height: 0.4rem;


    }

    .section_7a {


      .btn_sty {


        height: 0.6rem;
        font-size: 0.24rem;
      }
    }

    .font_6a {
      font-size: .24rem;
      line-height: 1;

    }

    .text_18a {
      line-height: 1;
    }

    .group_3a {
      margin-top: .2rem;
    }

    .space-x-477a > *:not(:first-child) {
      margin-left: 4.77rem;
    }

    .image_2a {
      width: 0.5rem;
      height: 0.5rem;
    }

    .divider_2a {


    }

    .group_4a {


    }

    .space-x-16a > *:not(:first-child) {
      margin-left: 0.2rem;
    }

    .section_8a {


      height: 0.6rem;



    }

    .space-x-7a > *:not(:first-child) {
      margin-left: 0.1rem;
    }

    .section_11a {

    }

    .section_13a {

    }

    .text-wrapper_5a {


    }

    .font_8a {
      font-size: 0.2rem;


    }

    .font_7a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_11a {

    }

    .text_28a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_21a {
      font-size: 0.38rem;
      line-height: 1;
    }

    .text_38a {
      line-height: 0.2rem;
    }

    .text_37a {
      line-height: 1;
    }

    .text_32a {
      line-height: 1;
    }

    .text_30a {
      line-height: 1;
    }

    .text_27a {


      line-height: 1.2;
    }

    .text_19a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_15a {
      line-height: 1;
    }

    .text_12a {
      line-height:1;
    }

    .text_24a {
      line-height: 1;
    }

    .section_9a {

      height: .6rem;
    }

    .section_12a {

      width: 0.5rem;
      height: 0.5rem;
    }

    .text_29a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_25a {
      line-height: 1;
    }

    .section_10a {

      height: 0.6rem;
    }

    .space-x-8a > *:not(:first-child) {
      margin-left: 0.08rem;
    }

    .text_26a {
      line-height:1;
    }

    .group_8a {


      .el-radio {


        ::v-deep .el-radio__input {

        }

        ::v-deep .el-radio__label {

        }
      }
    }

    .text_33a {
      line-height:1;
    }

    .image_3a {

      width: 0.5rem;
      height: 0.5rem;
    }

    .image_6a {
      width: 0.3rem;
      height: 0.3rem;
    }

    .text_35a {
      margin-left: 0.1rem;
      line-height: 1;
    }

    .image_7a {
      width: 2.3rem;
      height: 2.3rem;
    }
    .hint_pay{




    }
  }

}

</style>
