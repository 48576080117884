<template>
  <div class="pub_-flex-col pub_-justify-start page">
    <div class="pub_-flex-col pub_-justify-start section">
      <div class="pub_-flex-col pub_-justify-start pub_-items-start section_2">
        <div class="pub_-flex-col pub_-justify-start pub_-items-end section_3">
          <div class="pub_-flex-col section_4 space-y-61">
            <div class="pub_-flex-col">
              <div class="pub_-flex-row pub_-items-center group space-x-101">
                <img
                  class="image"
                  @click="go_href()"
                  src="../../assets/86cf0eab57062a0b74caf291a1f26fe1.png"
                />

                <span class="text text_2">注册</span>
              </div>
              <div
                class="
                  pub_-flex-row pub_-items-center
                  section_6
                  view
                  space-x-133
                "
              >
                <div class="pub_-flex-row pub_-items-center pub_-relative">
                  <div
                    class="
                      pub_-flex-row pub_-items-center pub_-shrink-0
                      section_5
                    "
                    :class="{ on: selOn == true }"
                  >
                    <div class="sel_btn" @click="selShow">
                      <span class="font_1 text text_3">{{ typeT }}</span>
                      <img
                        class="pub_-shrink-0 image_5"
                        src="../../assets/efdde20dd7b5d9af526b5c7bd481b651.png"
                      />
                    </div>

                    <div class="sel_box">
                      <ul>
                        <li
                          @click="selS('mobile', '手机号')"
                          data-type="mobile"
                          data-name="手机号"
                        >
                          手机号
                        </li>
                        <li
                          @click="selS('email', '邮箱')"
                          data-type="email"
                          data-name="邮箱"
                        >
                          邮箱
                        </li>
                      </ul>
                    </div>
                  </div>
                  <input
                    v-if="typeN == 'mobile'"
                    class="font_1 text text_4"
                    type="number"
                    placeholder="1**********"
                    v-model="value"
                  />
                  <input
                    v-else
                    class="font_1 text text_4"
                    type="email"
                    placeholder="****@**.com"
                    v-model="value"
                  />
                </div>
                <img
                  class="image_3 image_4"
                  src="../../assets/ce177567b6f6f507693e20e23fb3b9eb.png"
                  @click="empty_1"
                />
              </div>
              <div class="pub_-flex-row group_2 space-x-18">
                <div
                  class="pub_-flex-row pub_-items-center section_7 space-x-78"
                >
                  <input class="font_1 text text_6" v-model="code" />
                  <img
                    class="pub_-shrink-0 image_3"
                    src="../../assets/ce177567b6f6f507693e20e23fb3b9eb.png"
                    @click="empty_2"
                  />
                </div>
                <div
                  class="
                    pub_-flex-col pub_-justify-start pub_-items-center
                    text-wrapper
                  "
                >
                  <button
                    type="button"
                    class="font_2 text text_5"
                    @click="verify"
                    :disabled="disabled"
                  >
                    {{ btntxt }}
                  </button>
                </div>
              </div>
              <div
                class="
                  pub_-flex-row pub_-items-center
                  section_6
                  view_2
                  space-x-14
                "
              >
                <img
                  class="image_6"
                  src="../../assets/01a65ec011bc9c28be9bc8ed92180bc5.png"
                />
                <input
                  class="font_2 text text_7"
                  type="password"
                  placeholder="请输入密码"
                  v-model="password"
                />
              </div>


              <div class="n_form_sty" >
                <label>课程顾问</label>
                <input
                  class="font_2 text text_7"
                  type="text"
                  placeholder="选填"
                  v-model="salesman"
                />
              </div>

              <div
                class="
                  pub_-flex-col pub_-justify-start pub_-items-center
                  text-wrapper_2
                  btn_sty
                "
                @click="opt"
              >
                <span class="text text_8">登录</span>
              </div>
            </div>
            <div
              class="pub_-flex-row pub_-items-center group_3 space-x-14 hend_c"
            >
              <!-- <RouterLink to="/ewm">
                <img
                  class="image_7"
                  src="../../assets/a129daff2b6c1c1917ddd5efe52c95f1.png"
                />
                <span class="font_1 text">微信</span>
              </RouterLink> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      count: "",
      timer: null,
      show: true,
      typeN: "mobile",
      typeT: "手机号",
      selOn: false,
      code: "",
      password: "",

      salesman:"",//课程顾问

      btntxt: "获取验证码",
      disabled: false,
      time: 0,

      email: "",
      phone: "",
      value: "",
    };
  },
  mounted() {
    //  this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.register(); //注册接口
    },
    async register(id, i) {
      this.verify(true);

      let param = {
        type: this.typeN,
        value: this.value,
        code: this.code,
        password: this.password,
        salesman:this.salesman,
      };


      if (this.code == "") {
        this.$message({
          message: "请输入验证码号",
          type: "warning",
        });
        return false;
      }

      // else if (this.code != this.value) {
      //   this.$message({
      //     message: "验证码错误，测试阶段验证码同账号",
      //     type: "warning",
      //   });
      // }

      if (this.password == "" || this.password.length < 6) {
        this.$message({
          message: "请输入密码并且密码不能少于6位",
          type: "warning",
        });
        return false;
      }

      const { data: res } = await this.$http.post(
        "/api.student/register",
        param
      );

      console.log(res);
      if (res.code != 0) {
        this.$message({
          message: res.msg,
          type: "error",
        });
        if (res.msg == "注册账号已存在") {
          this.$router.push("/login");
        }
      } else {
        this.$message({
          message: "注册成功",
          type: "success",
        });

        this.$router.push("/login");
      }
    },
    opt() {
      this.ajaxH(null, 0);
    },

    verify(ste) {
      if (this.typeN == "mobile") {
        this.sendcode(ste);
      } else {
        this.sendEmail(ste);
      }
    },
    //验证手机号码部分
    sendcode(str) {
      // var reg = 11 && /^((13|14|15|17|18)[0-9]{1}d{8})$/;
      var reg = 11 && /^[1][3,4,5,7,8,9][0-9]{9}$/;
      //var url="/nptOfficialWebsite/apply/sendSms?mobile="+this.ruleForm.phone;
      console.log(this.value);
      if (this.value == "") {
        // alert("请输入手机号码");
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
        return false;
      } else if (!reg.test(this.value)) {
        this.$message({
          message: "手机格式不正确",
          type: "error",
        });

      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;

          this.sendVerifyCode();
        }
      }
    },
    //邮箱验证
    sendEmail(str) {
      var regEmail =
        /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/;
      if (this.value == "") {
        this.$message({
          message: "请输入邮箱",
          type: "warning",
        });
        return false;
      } else if (!regEmail.test(this.value)) {
        this.$message({
          message: "邮箱格式不正确",
          type: "error",
        });
      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;

          this.sendVerifyCode();
        }
      }
    },
    timerC() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timerC, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    async sendVerifyCode(id, i) {
      let self = this;
      let param = {
        type: this.typeN,
        value: this.value,
        purpose: 1,
      };
      const { data: res } = await this.$http.post(
        "/api.verify/sendVerifyCode",
        param
      );
      console.log(res);
      if (res.code == 1){
        this.$message({
          message: res.msg,
          type: "error",
        });
      }else {
         this.timerC();
      }



      // this.code = res.data;
      // console.log(this.code);
    },

    go_href() {
      this.$router.go(-1);
    },

    selS(n, t) {
      if (n != this.typeN) {
        this.value = "";
        this.typeN = n;
        this.typeT = t;
      }
      this.selOn = false;
    },
    selShow() {
      if (this.selOn == false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },
    empty_1() {
      this.value = "";
    },
    empty_2() {
      this.code = "";
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .hend_c {
    cursor: pointer;
  }
}
.section_2 {
  background-image: url("../../assets/log001.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.section_4 {
  margin: 0 auto;
  padding: 0.2rem 0 0.1rem;
  border-radius: 0.1rem;
  background-image: url("../../assets/1e3f75b209f66f1dfffd325194986e79.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 3.46rem;
}

.group {
  padding: 0 0.27rem 0.19rem;
  border-bottom: solid 0.01rem #f0f2f4;
}
.space-x-101 > *:not(:first-child) {
  margin-left: 1.01rem;
}
.image {
  width: 0.09rem;
  height: 0.15rem;
  cursor: pointer;
  transition: transform 0.5s ease;
  @media screen and (orientation: landscape) {
    &:hover {
      transform: scale(1.2);
    }
  }
}
.section_6 {
  background-color: #ffffff;
  border-radius: 0.04rem;
  border: solid 0.01rem #c2c2c2;
}
.view {
  margin: 0.28rem 0.2rem 0;
}
.space-x-133 > *:not(:first-child) {
  margin-left: 0.25rem;
}
.section_5 {
  padding: 0.12rem 0.06rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  height: 0.39rem;
  border: solid 0.01rem #c2c2c2;
  margin: -0.01rem;
  position: relative;
  width: 0.7rem;

  .sel_btn {
    cursor: pointer;
    .font_1 {
      transform: color 0.5s ease;
    }
    @media screen and (orientation: landscape) {
      .font_1 {
        color: #2080f7;
      }
    }
  }

  &.on {
    .sel_box {
      transform: none;
      opacity: 1;
      pointer-events: all;
    }
  }

  .sel_box {
    position: absolute;
    width: 200%;
    left: 0;
    top: 100%;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0 0.2rem 0.6rem -0.15rem rgba($color: #000000, $alpha: 0.2);
    border-radius: 0.04rem;

    transition: all 0.5s ease;
    transform: translateY(0.5rem);
    opacity: 0;
    pointer-events: none;

    ul {
      display: block;
      padding: 0.1rem 0;
      margin: 0;
      li {
        display: block;
        font-size: 0.14rem;
        line-height: 2.5;
        padding: 0 0.2rem;
        cursor: pointer;
        transition: color 0.5s ease;

        @media screen and (orientation: landscape) {
          &:hover {
            color: #2080f7;
          }
        }
      }
    }
  }
}
.font_1 {
  font-size: 0.14rem;
  line-height: 0.14rem;
}
.image_5 {
  width: 0.1rem;
  height: 0.06rem;
}
.image_2 {
  width: 0.01rem;
  height: 0.24rem;
}
.pos {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.image_3 {
  width: 0.13rem;
  height: 0.13rem;
  cursor: pointer;
  transition: transform 0.5s ease;
  @media screen and (orientation: landscape) {
    &:hover {
      transform: scale(1.2);
    }
  }
}
.image_4 {
  margin-right: 0.13rem;
}
.group_2 {
  padding: 0.18rem 0.2rem;
}
.space-x-18 > *:not(:first-child) {
  margin-left: 0.18rem;
}
.section_7 {
  padding: 0.12rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  height: 0.39rem;
  border: solid 0.01rem #c2c2c2;
  width: calc(100% - 1.19rem);
}
.space-x-78 > *:not(:first-child) {
  margin-left: 0.12rem;
}
.text-wrapper {
  background-color: #ffffff;
  border-radius: 0.04rem;
  width: 1.01rem;
  height: 0.39rem;
  border: solid 0.01rem #c2c2c2;
}
.font_2 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.14rem;
}
.text {
  text-transform: uppercase;
}
.text_6 {
  line-height: 0.07rem;
  width: calc(100% - 0.25rem);
}
.text_4 {
  margin-left: 0.13rem;
  line-height: 0.13rem;
}
.text_3 {
  color: #2b2c30;
}

.sel_btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.text_2 {
  margin-right: 1.1rem;
  color: #2b2c30;
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.19rem;
}
.text_5 {
  color: #2080f7;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  letter-spacing: -0.07em;
  transition: all 0.5s ease;
  cursor: pointer;
  &:disabled {
    color: rgba(16, 16, 16, 0.3);
    background-color: rgba(239, 239, 239, 0.3);

    cursor: auto;
    pointer-events: none;
  }
  @media screen and (orientation: landscape) {
    &:hover {
      color: #fff;
      background-color: #2080f7;
    }
  }
}
.view_2 {
  margin: 0 0.2rem;
}
.image_6 {
  border-radius: 0.04rem;
  width: 0.39rem;
  height: 0.39rem;
  margin: -0.01rem;
}
.text_7 {
  width: 2.5rem;
}
.text-wrapper_2 {
  margin: 0.19rem 0.2rem 0;
  border-radius: 0.04rem;
  width: 3.05rem;
  height: 0.38rem;
}
.text_8 {
  font-size: 0.2rem;
  line-height: 1;
}
.group_3 {
  padding: 0 0.21rem;
  margin-top: 0.61rem;
  a {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.14rem;
      color: rgba(8, 44, 71, 1);
      transition: color 0.5s ease;
    }

    @media screen and (orientation: landscape) {
      &:hover {
        span {
          color: #2080f7;
        }
      }
    }
  }
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}
.image_7 {
  border-radius: 0.14rem;
  width: 0.28rem;
  height: 0.28rem;
}
.n_form_sty{
  display: flex;
  align-items: center;
  margin: .18rem .2rem 0;
  border: .01rem solid #c2c2c2;
  border-radius: .04rem;
  label{
    font-size: .14rem;
    width: .7rem;
    border: .01rem solid #c2c2c2;
    margin: -.01rem;
    padding: 0 0.06rem;
    height: 0.39rem;
    display: flex;
    align-items: center;
    border-radius: .04rem;
  }
  input{

    line-height: 1;
    font-size: .14rem;
    padding-left: .15rem;
    border: none;
    background-color: transparent;
    outline: none;
    width: calc(100% - .7rem);
  }
}

@media screen and (orientation: portrait) {
  .section_2 {
    background-size: cover;
  }
  .section_4{
    width: 80vw;
    padding: .4rem 0 .2rem;
  }
  .text_2{
    font-size: .32rem;
    line-height: 1;
  }
  .image_6{
    width: .7rem;
    height: .7rem;
  }
  .space-x-101 > *:not(:first-child){
    margin: 0;
  }
  .group{
    padding: .2rem .4rem;
    position: relative;
    justify-content: center;

    .image{
      width: .28rem;
      height: auto;
      left: .4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

    }
  }
  .group_2{
    margin: .3rem .4rem 0;
    padding: 0;
  }
  .section_7{
    height: .7rem;
    width: calc(100%  - 2rem);
  }
  .view,.view_2{
    margin: .3rem .4rem 0;
  }

  .section_5{
    height: .7rem;
    width: 1.4rem;
    padding-left: .1rem;
    padding-right: .1rem;

    .sel_box {
      ul{
        li{
          font-size: .24rem;

        }
      }
    }
  }

  .font_1{
    font-size: .24rem;
    line-height: 1;

  }
  .image_5{
    width: .2rem;
    height: 0.12rem;
  }

  .image_3{
    width: .2rem;
    height: auto;
  }



  .text-wrapper{
    width: calc(2rem - .18rem);
    margin: 0 .4rem;
    height: .7rem;

  }

  .text-wrapper_2{
    width: calc(100% - .8rem);
    margin: .3rem .4rem .4rem;
    height: .7rem;
  }

  .n_form_sty{
    margin: .3rem .4rem 0;
    border: .01rem solid #c2c2c2;
    border-radius: .04rem;

    label{
      font-size: .24rem;
      width: 1.4rem;
      padding-left: .1rem;
      padding-right: .1rem;

      height: 0.7rem;
      border-radius: .04rem;
    }
    input{
      font-size: .24rem;
      padding-left: .19rem;
      width: calc(100% - 1.4rem);
    }
  }
.font_2,.text_8
{
  font-size: .24rem;
}
}


</style>
